import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";

export default class ManageCorporateInvoiceJobStore {
	per_page_overview = ServerGridConfig.options.paginationPageSize;
	summary_filter = null;
	agGridSummary = null;
	agGridOverview = null;
	per_page_summary = ServerGridConfig.options.paginationPageSize;
	current_page_summary = 1;
	list_data_summary = null;
	list_data = null;
	total_summary = 0;
	allColumnIdsSummary = [];
	overview_filter = null;
	deleteValues = null;
	
	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGridSummary.api.paginationSetPageSize(parseInt(page));
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					advance_filter: this.overview_filter ? this.overview_filter : this.summary_filter,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				if (this.summary_filter) {
					this.getList(payload).then((data) => {
						if (data.list.total === 0) {
							this.agGridSummary.api.showNoRowsOverlay();
						} else {
							this.agGridSummary.api.hideOverlay();
						}
						params.successCallback(data.list.data, data.list.total);
						var allColumnIds = [];
						if (this.agGridSummary && this.agGridSummary.columnApi && data.total) {
							this.agGridSummary.columnApi.getAllColumns().forEach(function (column) {
								allColumnIds.push(column.col_id);
							});
						}
					})
				}
				else if (this.overview_filter) {
					this.getListOverview(payload).then((data) => {
						if (data.overview.total === 0) {
							this.agGridOverview.api.showNoRowsOverlay();
						} else {
							this.agGridOverview.api.hideOverlay();
						}
						params.successCallback(data.overview.data, data.overview.total);
						var allColumnIds = [];
						if (this.agGridOverview && this.agGridOverview.columnApi && data.total) {
							this.agGridOverview.columnApi.getAllColumns().forEach(function (column) {
								allColumnIds.push(column.col_id);
							});
						}
					})
				}
			},
		};
	};

	// set form values to  customefilter
	setSummaryFilterValues = (data) => {
		return new Promise((resolve) => {
			this.summary_filter = data;
			if (this.agGridSummary) {
				this.setupGridSummary(this.agGridSummary);
			}
			resolve()
		});
	};

	setOverviewFilterValues = (data) => {
		return new Promise((resolve) => {
			this.overview_filter = data;
			if (this.agGridOverview) {
				this.setupGridOverview(this.agGridOverview);
			}
			resolve()
		});
	};

	setupGridSummary = (params) => {
		this.agGridSummary = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	setupGridOverview = (params) => {
		this.agGridOverview = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};
	setOverviewFilter = (data) => {
		this.overview_filter = data;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSizeSummary = (page = this.per_page_summary) => {
		this.per_page_summary = page;
		if (this.agGridSummary) {
			this.agGridSummary.api.paginationSetPageSize(parseInt(page));
		}
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSizeOverview = (page = this.per_page_overview) => {
		this.per_page_overview = page;
		if (this.agGridOverview) {
			this.agGridOverview.api.paginationSetPageSize(parseInt(page));
		}
	};

	// Filter function for no record found message
	onFilterChangedSummary = (params) => {
		this.agGridSummary = params;
		if (this.agGridSummary && this.agGridSummary.api.getModel().getRowCount() === 0) {
			this.agGridSummary.api.showNoRowsOverlay();
		}
		if (this.agGridSummary && this.agGridSummary.api.getModel().getRowCount() > 0) {
			this.agGridSummary.api.hideOverlay();
		}
	};
	// Filter function for no record found message
	onFilterChangedOverview = (params) => {
		this.agGridOverview = params;
		if (this.agGridOverview && this.agGridOverview.api.getModel().getRowCount() === 0) {
			this.agGridOverview.api.showNoRowsOverlay();
		}
		if (this.agGridOverview && this.agGridOverview.api.getModel().getRowCount() > 0) {
			this.agGridOverview.api.hideOverlay();
		}
	};

	refreshList = () => {
		if (this.agGridSummary) {
			this.setupGridSummary(this.agGridSummary);
		}
	}
	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		return { final_filter, final_sort }
	}
	
	getListOverview = (payload) => {
		return Axios.post(`ho-activities/corporate-invoices-job/overview`, payload).then(({ data }) => {
			if (data.overview.data.length) {
				let startPage = (data.overview.current_page - 1) * data.overview.per_page;
				data.overview.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.overview_data_summary = data.overview ? data.overview.data : null;
			this.total_summary = data.overview.total;
			this.current_page_summary = data.overview.current_page;
			return data;
		});
	};

	getList = (payload) => {
		return Axios.post(`/ho-activities/corporate-invoices-job/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data_summary = data.list ? data.list.data : null;
			this.total_summary = data.list.total;
			this.current_page_summary = data.list.current_page;
			return data;
		});
	};

	generateBill = (data) => {
		return Axios.post(`/ho-activities/corporate-invoices-job/generate`, data)
			.then(({ data }) => {
				this.agGridOverview.api.deselectAll();
				if (this.agGridOverview) {
					this.setupGridOverview(this.agGridOverview);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call delete api
	DeleteData = (id) => {
		return Axios.delete(`/ho-activities/corporate-invoices-job/cancellation/${id}`)
			.then(({ data }) => {
				if (this.agGridSummary) {
					this.setupGridSummary(this.agGridSummary);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
}

decorate(ManageCorporateInvoiceJobStore, {
	setSummaryFilterValues: action,
	setOverviewFilterValues: action,
	onFilterChangedOverview: action,
	per_page_summary: observable,
	per_page_overview: observable,
	agGridSummary: observable,
	list_data_summary: observable,
	list_data: observable,
	total_summary: observable,
	allColumnIdsSummary: observable,
	setupGridSummary: action,
	setupGridOverview: action,
	setPageSizeSummary: action,
	getListOverview: action,
	onFilterChangedSummary: action,
	summary_filter: observable,
	agGridOverview: observable,
	setOverviewFilter: action,
	overview_filter: observable,
	setDeleteValues: action,
	deleteValues: observable,
});