import Axios from "axios";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import { Constant } from "../../../../../config/Constant";
import ServerGridConfig from "../../../../../config/ServerGridConfig";

export default class AgencyPaymentReportStore {
	filter = null;
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	viewValues = null;
	total = 0;
	allColumnIds = [];
	bookingGrid = null
	list_data_bookings = null;
	total_bookings = 0;


	// set form values to  customefilter
	setFilterValues = (data = null) => {
		return new Promise((resolve) => {
			this.filter = data;
			if (this.agGrid && data) {
				this.setupGrid(this.agGrid);
			}
			resolve()
		});
	};

	// set form values to view
	setViewValues = (data) => {
		if (data && data?.booking_detail) {
			this.setListData(data?.booking_detail);
		}
		this.viewValues = data;
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};
	// Setup grid and set column size to autosize
	setupBookingGrid = (params) => {
		this.bookingGrid = params;
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Filter function for no record found message
	onBookingsFilterChanged = (params) => {
		this.bookingGrid = params;
		if (this.bookingGrid && this.bookingGrid.api.getModel().getRowCount() === 0) {
			this.bookingGrid.api.showNoRowsOverlay();
		}
		if (this.bookingGrid && this.bookingGrid.api.getModel().getRowCount() > 0) {
			this.bookingGrid.api.hideOverlay();
		}
	};

	setListData = (data) => {
		if (this.bookingGrid) {
			var filter = this.bookingGrid.api.getFilterModel();
			var sort = this.bookingGrid.api.getSortModel();
		}
		this.list_data_bookings = data ? data : null;
		this.total_bookings = data?.length;
		if (this.bookingGrid) {
			this.bookingGrid.api.setFilterModel(filter);
			this.bookingGrid.api.setSortModel(sort);
		}
	}
	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		return { final_filter, final_sort };
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					advance_filter: this.generatePayload(this.filter),
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data?.list?.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data?.list?.data, data?.list?.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data?.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				})
			},
		};
	};

	generatePayload = (filter) => {
		if (filter.from_date) {
			filter.from_date = moment(filter.from_date).format(Constant.PAYLOAD_DATE);
		}
		if (filter.to_date) {
			filter.to_date = moment(filter.to_date).format(Constant.PAYLOAD_DATE);
		}
		return filter;
	}

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	getList = (payload) => {
		return Axios.post(`/international/int-payment-report`, payload).then(({ data }) => {
			if (data?.list?.data?.length) {
				let startPage = (data?.list?.current_page - 1) * data?.list?.per_page;
				data?.list?.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data = data?.list ? data?.list?.data : null;
			this.total = data?.list?.total;
			this.current_page = data?.list?.current_page;
			return data;
		});
	};

	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`international/int-booking-payment/${ID}`).then(({ data }) => {
			this.setViewValues(data.view);
			return data;
		});
	};


	doExportDownload = (data) => {
		return Axios.post(`/international/int-payment-report`, { advance_filter: data })
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
}

decorate(AgencyPaymentReportStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	total: observable,
	allColumnIdsDetail: observable,
	filter: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	setFilterValues: action,
	viewValues: observable,
	setViewValues: action,
	bookingGrid: observable,
	setupBookingGrid: action,
	onBookingsFilterChanged: action,
	list_data_bookings: observable,
	total_bookings: observable,
	setListData: action

});

