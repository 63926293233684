import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../../../config/messages/common";
import ServerGridConfig from "../../../../config/ServerGridConfig";

export default class ManageBillingStore {
	detail_filter = null;
	agGridDetail = null;
	per_page_overview = ServerGridConfig.options.paginationPageSize;
	current_page_detail = 1;
	list_data_detail = null;
	total_detail = 0;
	allColumnIdsDetail = [];
	//This is for summary
	summary_filter = null;
	agGridSummary = null;
	agGridOverview = null;
	agGridCancelled = null
	per_page_summary = ServerGridConfig.options.paginationPageSize;
	per_page_cancelled = ServerGridConfig.options.paginationPageSize;
	current_page_summary = 1;
	list_data_summary = null;
	list_data = null;
	total_summary = 0;
	allColumnIdsSummary = [];
	overview_filter = null;
	cancelled_filter = null;
	deleteValues = null;
	sendemailValues = null;
	viewValues = null;
	regenerateStatusValues = null;
	daliyBookingValues = null;
	paymentsDetailValues = null;
	selectAll = false;
	selectAllBillPrinting = false;
	selectCount = 0;
	isHistory = 0;

	setCheckState = (data) => {
		this.selectAll = data
	}
	setCheckBillPrintingState = (data) => {
		this.selectAllBillPrinting = data
	}

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to Send Email
	setSendEmailValues = (data) => {
		this.sendemailValues = data;
	};

	// set form values to view
	setViewValues = (data) => {
		this.viewValues = data;
	};

	setRegenerateStatusValues = (data) => {
		this.regenerateStatusValues = data;
	};

	// set form values to view
	setDaliyBookingValues = (data) => {
		this.daliyBookingValues = data;
	};

	// set form values to view
	setPaymentsDetailValues = (data) => {
		this.paymentsDetailValues = data;
	};

	setIsHistory = (is_deleted) => {
		this.isHistory = is_deleted
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGridSummary.api.paginationSetPageSize(parseInt(page));
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					is_history: this.cancelled_filter ? this.isHistory : null,
					advance_filter: this.overview_filter ? this.generatePayload(this.overview_filter) : this.summary_filter ? this.generatePayload(this.summary_filter) : this.generatePayload(this.cancelled_filter),
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				if (this.summary_filter) {
					this.getList(payload).then((data) => {
						if (data.list.total === 0) {
							this.agGridSummary.api.showNoRowsOverlay();
						} else {
							this.agGridSummary.api.hideOverlay();
						}
						params.successCallback(data.list.data, data.list.total);
						var allColumnIds = [];
						if (this.agGridSummary && this.agGridSummary.columnApi && data.total) {
							this.agGridSummary.columnApi.getAllColumns().forEach(function (column) {
								allColumnIds.push(column.col_id);
							});
						}
					})
				}
				else if (this.overview_filter) {
					this.getListOverview(payload).then((data) => {
						if (data.overview.total === 0) {
							this.agGridOverview.api.showNoRowsOverlay();
						} else {
							this.agGridOverview.api.hideOverlay();
						}
						params.successCallback(data.overview.data, data.overview.total);
						var allColumnIds = [];
						if (this.agGridOverview && this.agGridOverview.columnApi && data.total) {
							this.agGridOverview.columnApi.getAllColumns().forEach(function (column) {
								allColumnIds.push(column.col_id);
							});
						}
					}).then(() => this.onFirstBillPrintingDataRendered())
				}
				else if (this.cancelled_filter) {
					this.getListCancelled(payload).then((data) => {
						if (data.list.total === 0) {
							this.agGridCancelled.api.showNoRowsOverlay();
						} else {
							this.agGridCancelled.api.hideOverlay();
						}
						params.successCallback(data.list.data, data.list.total);
						var allColumnIds = [];
						if (this.agGridCancelled && this.agGridCancelled.columnApi && data.total) {
							this.agGridCancelled.columnApi.getAllColumns().forEach(function (column) {
								allColumnIds.push(column.col_id);
							});
						}
					}).then(() => this.onFirstDataRendered())
				}
				// this.summary_filter ?
				//     this.getList(payload).then((data) => {
				//         if (data.list.total === 0) {
				//             this.agGridSummary.api.showNoRowsOverlay();
				//         } else {
				//             this.agGridSummary.api.hideOverlay();
				//         }
				//         params.successCallback(data.list.data, data.list.total);
				//         var allColumnIds = [];
				//         if (this.agGridSummary && this.agGridSummary.columnApi && data.total) {
				//             this.agGridSummary.columnApi.getAllColumns().forEach(function (column) {
				//                 allColumnIds.push(column.col_id);
				//             });
				//         }
				//     }) :
				//     this.getListOverview(payload).then((data) => {
				//         if (data.overview.total === 0) {
				//             this.agGridOverview.api.showNoRowsOverlay();
				//         } else {
				//             this.agGridOverview.api.hideOverlay();
				//         }
				//         params.successCallback(data.overview.data, data.overview.total);
				//         var allColumnIds = [];
				//         if (this.agGridOverview && this.agGridOverview.columnApi && data.total) {
				//             this.agGridOverview.columnApi.getAllColumns().forEach(function (column) {
				//                 allColumnIds.push(column.col_id);
				//             });
				//         }
				//     })
			},
		};
	};

	// set form values to  customefilter
	setSummaryFilterValues = (data) => {
		return new Promise((resolve) => {
			this.summary_filter = data;
			if (this.agGridSummary) {
				this.setupGridSummary(this.agGridSummary);
			}
			resolve()
		});
	};

	// set form values to  customefilter
	setOverviewFilterValues = (data) => {
		return new Promise((resolve) => {
			this.overview_filter = data;
			if (this.agGridOverview) {
				this.setupGridOverview(this.agGridOverview);
			}
			resolve()
		});
	};

	// set form values to  customefilter
	setCancelledFilterValues = (data) => {
		return new Promise((resolve) => {
			this.cancelled_filter = data;
			if (this.agGridCancelled) {
				this.setupGridCancelled(this.agGridCancelled);
			}
			resolve()
		});
	};

	
	refreshCancelledList = () => {
		if (this.agGridCancelled) {
			this.setupGridCancelled(this.agGridCancelled);
		}
	}

	// Setup grid and set column size to autosize
	setupGridSummary = (params) => {
		this.agGridSummary = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};


	// Setup grid and set column size to autosize
	setupGridOverview = (params) => {
		// this.agGridOverview = params;
		this.agGridOverview = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};
	setOverviewFilter = (data) => {
		this.overview_filter = data;
	};

	// Setup grid and set column size to autosize
	setupGridCancelled = (params) => {
		// this.agGridCancelled = params;
		this.agGridCancelled = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};
	
	setCancelledFilter = (data) => {
		this.cancelled_filter = data;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSizeSummary = (page = this.per_page_summary) => {
		this.per_page_summary = page;
		if (this.agGridSummary) {
			this.agGridSummary.api.paginationSetPageSize(parseInt(page));
		}
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSizeOverview = (page = this.per_page_overview) => {
		this.per_page_overview = page;
		if (this.agGridOverview) {
			this.agGridOverview.api.paginationSetPageSize(parseInt(page));
		}
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSizeCancelled = (page = this.per_page_cancelled) => {
		this.per_page_cancelled = page;
		if (this.agGridCancelled) {
			this.agGridCancelled.api.paginationSetPageSize(parseInt(page));
		}
	};

	// Filter function for no record found message
	onFilterChangedSummary = (params) => {
		this.agGridSummary = params;
		if (this.agGridSummary && this.agGridSummary.api.getModel().getRowCount() === 0) {
			this.agGridSummary.api.showNoRowsOverlay();
		}
		if (this.agGridSummary && this.agGridSummary.api.getModel().getRowCount() > 0) {
			this.agGridSummary.api.hideOverlay();
		}
	};
	// Filter function for no record found message
	onFilterChangedOverview = (params) => {
		this.agGridOverview = params;
		if (this.agGridOverview && this.agGridOverview.api.getModel().getRowCount() === 0) {
			this.agGridOverview.api.showNoRowsOverlay();
		}
		if (this.agGridOverview && this.agGridOverview.api.getModel().getRowCount() > 0) {
			this.agGridOverview.api.hideOverlay();
		}
	};
	// Filter function for no record found message
	onFilterChangedCancelled = (params) => {
		this.agGridCancelled = params;
		if (this.agGridCancelled && this.agGridCancelled.api.getModel().getRowCount() === 0) {
			this.agGridCancelled.api.showNoRowsOverlay();
		}
		if (this.agGridCancelled && this.agGridCancelled.api.getModel().getRowCount() > 0) {
			this.agGridCancelled.api.hideOverlay();
		}
	};
	generatePayload = (summary_filter) => {
		// if (summary_filter.from_date) {
		//     summary_filter.from_date = moment(summary_filter.from_date).format('YYYY-MM-DD');
		// }
		// if (summary_filter.to_date) {
		//     summary_filter.to_date = moment(summary_filter.to_date).format('YYYY-MM-DD');
		// }
		return summary_filter;
	}

	refreshList = () => {
		if (this.agGridSummary) {
			this.setupGridSummary(this.agGridSummary);
		}
	}
	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		return { final_filter, final_sort }
	}
	// call api to get records
	getListOverview = (payload) => {
		// if (this.agGridOverview) {
		//     var filter = this.agGridOverview.api.getFilterModel();
		//     var sort = this.agGridOverview.api.getSortModel();
		// }
		// this.list_data = null;
		// let payload = this.overview_filter;
		// return Axios.post(`monthly-activities/client-invoices/overview`, { advance_filter: payload }).then(({ data }) => {
		//     if (data.overview.length) {
		//         data.overview.map((item, index) => {
		//             item.srno = index + 1;
		//             return null;
		//         });
		//     }
		//     this.list_data = data.overview ? data.overview : null;
		//     this.total = data.overview.length;
		//     this.current_page = 1;
		//     var allColumnIds = [];
		//     if (this.agGridOverview && this.agGridOverview.columnApi) {
		//         this.agGridOverview.columnApi.getAllColumns().forEach(function (column) {
		//             allColumnIds.push(column.colId);
		//         });
		//     }
		//     if (this.agGridOverview) {
		//         this.agGridOverview.api.setFilterModel(filter);
		//         this.agGridOverview.api.setSortModel(sort);
		//     }
		// });

		return Axios.post(`monthly-activities/client-invoices/overview`, payload).then(({ data }) => {
			if (data.overview.data.length) {
				let startPage = (data.overview.current_page - 1) * data.overview.per_page;
				data.overview.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.overview_data_summary = data.overview ? data.overview.data : null;
			this.total_summary = data.overview.total;
			this.current_page_summary = data.overview.current_page;
			return data;
		});
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`monthly-activities/client-invoices/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data_summary = data.list ? data.list.data : null;
			this.total_summary = data.list.total;
			this.current_page_summary = data.list.current_page;
			return data;
		});
	};

	// call api to get records
	getListCancelled = (payload) => {
		return Axios.post(`monthly-activities/client-invoices/cancel-list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data_summary = data.list ? data.list.data : null;
			this.total_summary = data.list.total;
			this.current_page_summary = data.list.current_page;
			return data;
		});
	};

	doGenerateBills = (data) => {
		return Axios.post(`/monthly-activities/client-invoices/generate`, data)
			.then(({ data }) => {
				if (this.agGridOverview) {
					this.agGridOverview.api.deselectAll();
					this.setupGridOverview(this.agGridOverview);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	doGenerateBillsPdf = (data) => {
		return Axios.post(`/monthly-activities/client-invoices/print`, data)
			.then(({ data }) => {
				if (this.agGridSummary) {
					this.agGridSummary.api.deselectAll();
					this.setupGridSummary(this.agGridSummary);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	ViewData = (formdata) => {
		return Axios.post(
			`/monthly-activities/client-invoices/detail`, formdata
		).then(({ data }) => {
			this.setViewValues(data.detail)
			return data.detail;
		});
	};

	// call api to get records
	DailyBookingsData = (ID) => {
		return Axios.get(
			`/monthly-activities/client-invoices/daily-bookings/${ID}`
		).then(({ data }) => {
			this.setDaliyBookingValues(data.daily_bookings_json)
			return data.daily_bookings_json;
		});
	};


	// call api to get Payments Detail records
	PaymentsDetailData = (formdata) => {
		return Axios.post(
			`/invoice-get-payments/detail`, formdata
		).then(({ data }) => {
			this.setPaymentsDetailValues(data.detail)
			return data.detail;
		});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.post(`/monthly-activities/client-invoices/cancellation`, formdata)
			.then(({ data }) => {
				this.agGridSummary.api.deselectAll();
				if (this.agGridSummary) {
					this.setupGridSummary(this.agGridSummary);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call reset Password api
	SendEmail = (data) => {
		return Axios.post(`/monthly-activities/client-invoices/invoices-email`, data)
			.then(({ data }) => {
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	doExportDownloadDetail = (data) => {
		return Axios.post(`monthly-activities/client-invoices/export`, { advance_filter: data })
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	onFirstDataRendered = () => {
		if (this.selectAll === true) {
			this.agGridOverview.api.forEachNode((node) => {
				this.selectCount++;
				return node.setSelected(!!node.data);
			});
		}
	};

	onSelectAllRender = (selectAllFlag) => {
		if (selectAllFlag === true) {
			this.onFirstDataRendered()
		} else {
			this.agGridOverview.api.forEachNode((node) => {
				return node.setSelected(false);
			});
		}
	}

	onFirstBillPrintingDataRendered = () => {
		if (this.selectAllBillPrinting === true) {
			this.agGridSummary.api.forEachNode((node) => {
				this.selectCount++;
				return node.setSelected(!!node.data);
			});
		}
	};

	onBillPrintingSelectAllRender = (selectAllFlag) => {
		if (selectAllFlag === true) {
			this.onFirstBillPrintingDataRendered()
		} else {
			this.agGridSummary.api.forEachNode((node) => {
				return node.setSelected(false);
			});
		}
	}
}

decorate(ManageBillingStore, {
	setSummaryFilterValues: action,
	setOverviewFilterValues: action,
	onFilterChangedOverview: action,
	per_page_summary: observable,
	per_page_overview: observable,
	per_page_cancelled: observable,
	agGridSummary: observable,
	list_data_summary: observable,
	list_data: observable,
	total_summary: observable,
	allColumnIdsSummary: observable,
	setupGridSummary: action,
	setupGridOverview: action,
	setPageSizeSummary: action,
	getListOverview: action,
	onFilterChangedSummary: action,
	summary_filter: observable,
	agGridOverview: observable,
	setOverviewFilter: action,
	overview_filter: observable,
	setDeleteValues: action,
	deleteValues: observable,
	setSendEmailValues: action,
	sendemailValues: observable,
	ViewData: action,
	setViewValues: action,
	viewValues: observable,
	setRegenerateStatusValues: action,
	regenerateStatusValues: observable,
	setDaliyBookingValues: action,
	daliyBookingValues: observable,
	setCancelledFilterValues: action,
	onFilterChangedCancelled: action,
	setupGridCancelled: action,
	getListCancelled: action,
	agGridCancelled: observable,
	setCancelledFilter: action,
	cancelled_filter: observable,
	selectAll: observable,
	setCheckState: action,
	onFirstDataRendered: action,
	onSelectAllRender: action,
	onBillPrintingSelectAllRender:action,
	selectCount: observable,
	setPaymentsDetailValues:action,
	paymentsDetailValues:observable,
	onFirstBillPrintingDataRendered:action,
	setCheckBillPrintingState:action,
	selectAllBillPrinting:observable,
	setIsHistory:action,
	isHistory:observable,
});