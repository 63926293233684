import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";

export default class AuditEntryStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	total = 0;
	allColumnIds = [];
	viewValues = null

	setViewValues = (data) => {
		this.viewValues = data;
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let dataSource = this.createDataSource(ServerGridConfig.options);
		api.setServerSideDatasource(dataSource);
	};

	// change page size, default page size is ServerGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`communication/notification/audit-entry`, payload).then(
			({ data }) => {
				if (data.list?.data?.length) {
					let startPage = (data?.list?.current_page - 1) * data?.list?.per_page;
					data?.list?.data.map((item, index) => {
						item.srno = startPage + index + 1;
						return null;
					});
				}
				this.list_data = data?.list ? data?.list?.data : null;
				this.total = data?.list?.total;
				this.current_page = data?.list?.current_page;
				return data;
			}
		);
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		return { final_filter, final_sort };
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDataSource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					purpose: 2,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				}

				this.getList(payload).then((data) => {
					if (data?.list?.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data?.list?.data, data?.list?.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};


	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`communication/notification/audit-entry/details/${ID}`).then(({ data }) => {
			this.setViewValues(data.view);
			return data;
		});
	};
}

decorate(AuditEntryStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	changeFilterAndSort: action,
	onFilterChanged: action,
	createDataSource: action,
	setViewValues: action,
	viewValues: observable,
});
