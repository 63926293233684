import Axios from "axios";
import Dexie from "dexie";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { b64toBlob, convertCaseBookingReceiverAddress, convertCaseBookingSenderAddress, convertTextToID } from "../../../../utils/GlobalFunction";
import { db } from "../../../../db";

export default class ManageAccountBookingStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	deleteValues = null;
	statusValues = null;
	viewValues = null;
	total = 0;
	allColumnIds = [];
	dropdown_city_list = null;
	pinCodeDetails = null;
	rateCardDetails = null;
	bookingPolicyDetails = null;
	localAgGrid = null;
	local_per_page = 6;
	local_current_page = 1;
	local_list_data = null;
	local_total = 0;
	local_allColumnIds = [];
	local_booking_list = {};
	today_booking_weight = 0;
	today_booking_amount = 0;
	last_booked_account_party = null;
	bulk_account_booking_list = null;
	serverFilter = null;
	advance_filter = null;
	ewayValues = null
	viewEwayValues = null

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to view
	setViewValues = (data) => {
		if (data) {
			data.address = convertCaseBookingSenderAddress(data);
			data.receiverAddress = convertCaseBookingReceiverAddress(data);
		}
		this.viewValues = data;
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// Set pincode data for booking type out of state
	setPinCodeDetails = (data) => {
		this.pinCodeDetails = data
	}

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	setServerFilter = (data) => {
		this.serverFilter = data;
	}

	setEwayValues = (data) => {
		this.ewayValues = data
	}

	SetViewEwayValues =(data) => {
		this.viewEwayValues = data
	}

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	// set form values to  customefilter
	setAdvanceFilterValues = (data = null) => {
		this.advance_filter = data;
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		if (this.serverFilter || localStorage.getItem('account_server_grid_filter')) {
			let account_server_grid_filter = localStorage.getItem('account_server_grid_filter') ? JSON.parse(localStorage.getItem('account_server_grid_filter'))  : this.serverFilter;
			this.agGrid.api.setFilterModel(account_server_grid_filter);
		} else {
			this.agGrid.api.setFilterModel({
				bk_date: { dateFrom: moment().format("YYYY-MM-DD"), dateTo: moment().add(1, 'day').endOf('day').format("YYYY-MM-DD"), type: "inRange", filterType: "date" }
			});
		}

		let datasource = this.createDatasource(ServerGridConfig.options);

		api.setServerSideDatasource(datasource)
	};

	// change page size, default page size is ServerGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`bookings/account/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		if (final_filter["status"]) {
			final_filter['status'].values = convertTextToID(final_filter['status'], this.isActiveFilterList, 'name', 'id')
		}
		return { final_filter, final_sort }
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	//setAdvanceFilter
	setAdvanceFilter = () => {
		let setAdvanceFilter = {};
		if (this.advance_filter) {
			if ('city' in this.advance_filter) {
				setAdvanceFilter.r_city_id = this.advance_filter.city.id;
			}
		}
		return setAdvanceFilter;
	}


	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var advance_filter = this.setAdvanceFilter();
				var payload = {
					advance_filter: advance_filter,
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}
				let account_filter = this.agGrid.api.getFilterModel();
				localStorage.setItem('account_server_grid_filter', JSON.stringify(account_filter));
				// this.serverFilter = account_filter;
				this.setServerFilter(account_filter);
				this.getList(payload).then((data) => {
					if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.list.data, data.list.total)
					var allColumnIds = []
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id)
						})
					}
				})
			}
		}
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`/bookings/account/account-booking-save`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				var lock = null;
				if(data?.lock){
					lock = data.lock;
				}
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify, lock });
			});
	};

	// Call edit api
	EditData = (ID, formdata) => {
		return Axios.post(`/bookings/account/account-booking-edit/` + ID, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`bookings/account/details/${ID}`).then(({ data }) => {
			this.setViewValues(data.view);
			return data;
		}).catch(() => {
			return Promise.reject();
		});
	};

	PrintReceiptData = (ID) => {
		return Axios.get(`/bookings/account/print-receipt/${ID}`).then(({ data }) => {
			const file = b64toBlob(data.view, "application/pdf");
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			window.open(fileURL);
			//pdfWindow.location.href = fileURL
		})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}

	MultiPrintReceiptData = (formdata) => {
		return Axios.post(`/bookings/account/bulk-print-receipt`, formdata).then(({ data }) => {
			return data;
		})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}

	// Check form local grid action's privileges 
	checkLocalPrivilege = (formData) => {
		return Axios.post(`/bookings/account/check-local-privilege`, formData).then((data) => {
			return data;
		}).catch(() => {
			return Promise.reject();
		});
	};

	// Call eway api
	EwayData = (ID, formdata) => {
		return Axios.post(`/bookings/eway/edit/` + ID, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//ViewEwayData api

	ViewEwayData = (formdata) => {
		return Axios.post(`bookings/eway/eway-no-details` ,formdata)
			.then(({ data }) => {
	            this.SetViewEwayValues(data);
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	getPincodeDetails = (conditional = {}) => {
		return Axios.post(`bookings/account/pincode-details`, conditional)
			.then(({ data }) => {
				this.rateCardDetails = data.pincodeDetails.rate_card;
				this.bookingPolicyDetails = data.pincodeDetails.booking_policies;
				return data.pincodeDetails;
			})
			.catch(response => {
				return Promise.reject(response)
			})
		// return Axios.post(`admin/countries/lov`, conditional)
		// 	.then(({ data }) => {
		// 		this.rateCardDetails = pincodeRes.pincodeDetails.rate_card;
		// 		return pincodeRes.pincodeDetails;
		// 	})
		// 	.catch(response => {
		// 		return Promise.reject(response)
		// 	})
	};
	JsonToFormData = (data) => {
		const formData = new FormData();
		Object.keys(data).forEach(key => {
			if (!['info', 'city', 'doc_file', 'bk_date', 'igst_chrg', 'sgst_chrg', 'cgst_chrg', 'valuation_chrg', 'fov_chrg', 'display_weight', 'display_v_weight', 'display_c_weight', 'location_gst_no', 'eway_details', 'is_city_oda1', 'parent_child'].includes(key)) {
				formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
			} else if (key === "city") {
				formData.append("r_city_id", (data[key] !== undefined && data[key] !== null) ? data[key] : '')
			}
		})
		return formData
	}
	getPastUserDetails = async (conditional = {}) => {
		let pastUser = [];
		if (conditional.is_who === 2) {
			pastUser = await db.receivers_ac.toArray();
			//pastUser = await db.receivers_ac.where("r_mobile").equals(conditional.mobile).first();
			// if (pastUser) {
			// 	// pastUser.name = pastUser.r_name;
			// 	// pastUser.email = pastUser.r_email;
			// 	// pastUser.whatsappno = pastUser.r_whatsappno;
			// 	// pastUser.address1 = pastUser.r_address1;
			// 	return pastUser;
			// }
		}
		return pastUser;
	}

	/**
	 * Below all things for local grid in booking screen
	 */
	// Setup grid and set column size to autosize
	setupLocalBookingGrid = (params) => {
		this.localAgGrid = params;
		this.localAgGrid.columnApi.applyColumnState({
			state: [{ colId: 'bk_date', sort: 'desc' }],
			defaultState: { sort: null },
		});
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setLocalBookingPageSize = (local_page = this.local_per_page) => {
		this.local_per_page = local_page;
		if (this.localAgGrid) {
			this.localAgGrid.api.paginationSetPageSize(parseInt(local_page));
		}
	};

	// call api to get records
	getLocalBookingList = async () => {
		if (this.localAgGrid) {
			var filter = this.localAgGrid.api.getFilterModel();
			var sort = this.localAgGrid.api.getSortModel();
		}
		this.local_list_data = null;
		let accountBooking = await db.bk_account.reverse().toArray();
		let result = {}, bookingWeight = 0, bookingAmount = 0;
		if (accountBooking.length) {
			accountBooking.map((item, index) => {
				item.srno = index + 1;
				return null;
			});
			await db.bk_account.orderBy('product_id').eachKey(product_id => {
				result[product_id] = (result[product_id] || 0) + 1;
			});
			await db.bk_account.each(item => {
				bookingWeight += Number(item.c_weight);
				bookingAmount += Number(item.gross);
			});
		}
		this.local_booking_list = result;
		this.today_booking_weight = bookingWeight;
		this.today_booking_amount = bookingAmount;
		this.local_list_data = accountBooking ? accountBooking : null;
		this.local_total = (accountBooking) ? accountBooking.length : 0;
		//this.local_current_page = data.list.current_page;
		var local_allColumnIds = [];
		if (this.localAgGrid && this.localAgGrid.columnApi) {
			this.localAgGrid.columnApi.getAllColumns().forEach(function (column) {
				local_allColumnIds.push(column.colId);
			});
		}
		if (this.localAgGrid) {
			this.localAgGrid.api.setFilterModel(filter);
			this.localAgGrid.api.setSortModel(sort);
		}
	};

	// Filter function for no record found message
	onLocalFilterChanged = (params) => {
		this.localAgGrid = params;
		if (this.localAgGrid && this.localAgGrid.api.getModel().getRowCount() === 0) {
			this.localAgGrid.api.showNoRowsOverlay();
		}
		if (this.localAgGrid && this.localAgGrid.api.getModel().getRowCount() > 0) {
			this.localAgGrid.api.hideOverlay();
		}
	};

	addBookingLocal = async (params) => {
		await db.bk_account.add({
			"id": params.id,
			"con_no": params.con_no,
			"bk_date": params.bk_date,
			"product_id": params.product_id,
			"product_name": params.product_name,
			"r_name": params.r_name,
			"r_city": params.r_city,
			"to_pincode": params.to_pincode,
			"s_name": params.s_name,
			"bk_type": params.bk_type,
			"c_weight": params.c_weight,
			"gross": params.gross
		});
		this.getLocalBookingList();
	}

	addBulkBookingLocal = async (data) => {
		await db.bk_account.bulkPut(data).then(function (lastKey) {
			console.log("Last accountList id was: " + lastKey);
			this.getLocalBookingList();
		}).catch(Dexie.BulkError, function (e) {
			console.error("Some accountList did not succeed.");
		});
	}
	

	updateReceiver = async (params) => {
		let existingReceiver = await db.receivers_ac.where("r_name").equals(params.r_name).count();
		if (existingReceiver) {
			// await db.receivers_ac.put(params, params.r_name).then(() => {
			// 	console.log("Last receiversAccount id was: ");
			// }).catch(Dexie.BulkError, function (e) {
			// 	console.error("Some receiversAccount did not succeed.");
			// });
			await db.receivers_ac.where("r_name").equals(params.r_name).modify(params).then(() => {
				console.log("Last receiversAccount id was: ");
			}).catch(Dexie.BulkError, function (e) {
				console.error("Some receiversAccount did not succeed.");
			});
		} else {
			await db.receivers_ac.add(params).then(() => {
				console.log("Last receiversAccount id was: ");
			}).catch(Dexie.BulkError, function (e) {
				console.error("Some receiversAccount did not succeed.");
			});
		}
	}

	// set last booking account party
	setLastBookedAccountParty = (data) => {
		this.last_booked_account_party = data;
	};

	VerifyImport = (formdata) => {
		this.bulk_account_booking_list = null
		return Axios.post(`bookings/account/verify`, formdata)
			.then(({ data }) => {
				if (data && data.import_data) {
					this.bulk_account_booking_list = data.import_data
				}
				return true;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	ImportBulk = (formdata) => {
		return Axios.post(`bookings/account/import`, formdata)
			.then(() => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return true;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call delete api
	DeleteData = (formdata, openFrom) => {
		return Axios.delete(`/bookings/account/destroy/` + formdata.id)
			.then(async ({ data }) => {
				await db.bk_account.where("id").equals(formdata.id)
					.delete()
					.then(function (deleteCount) {
						console.log("Deleted " + deleteCount + " objects");
					}).catch(Dexie.BulkError, function (e) {
						console.error("Some accountList did not succeed.");
					});

				if (openFrom === "form") {
					this.getLocalBookingList();
				} else if (openFrom === "list" && this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
}


decorate(ManageAccountBookingStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	deleteValues: observable,
	statusValues: observable,
	total: observable,
	allColumnIds: observable,
	dropdown_city_list: observable,
	setupGrid: action,
	setStatusValues: action,
	setPageSize: action,
	setEditValues: action,
	setDeleteValues: action,
	getList: action,
	changeFilterAndSort: action,
	onFilterChanged: action,
	createDatasource: action,
	setViewValues: action,
	viewValues: observable,
	setIsActiveFilterList: action,
	local_per_page: observable,
	localAgGrid: observable,
	local_list_data: observable,
	local_total: observable,
	lovcla_allColumnIds: observable,
	setupLocalBookingGrid: action,
	onLocalFilterChanged: action,
	local_booking_list: observable,
	today_booking_weight: observable,
	today_booking_amount: observable,
	setLastBookedAccountParty: action,
	last_booked_account_party: observable,
	rateCardDetails: observable,
	bulk_account_booking_list: observable,
	serverFilter: observable,
	setServerFilter: action,
	advance_filter: observable,
	setAdvanceFilterValues: action,
	ewayValues : observable,
	setEwayValues : action,
	viewEwayValues : observable,
	SetViewEwayValues : action
});
