/* eslint-disable no-self-assign */
import Axios from "axios";
import { action, decorate, observable } from "mobx";

export default class ManageTrackingStore {
	consignmentTrackingValues = null
	bagTrackingValues = null
	packetTrackingValues = null
	trackingErrMsg = null

	// set form values to edit
	setConsignmentTrackingValues = (data) => {
		this.consignmentTrackingValues = data;
	};
	// set form values to edit
	setBagTrackingValues = (data) => {
		this.bagTrackingValues = data;
	};

	// set form values to edit
	setPacketTrackingValues = (data) => {
		this.packetTrackingValues = data;
	};

	// set form values to edit
	setTrackingErrMsg = (data) => {
		this.trackingErrMsg = data;
	};


	// call api to Consignment Tracking records
	getConsignmentTrackingDataMobile = (formdata) => {
		return Axios.post(`/utilities/tracking/web-view/${formdata.con_no}`, formdata
		).then(({ data }) => {
			this.setConsignmentTrackingValues(data.view)
			return data.view;
		}).catch(({ response: { data } }) => {
			var errors = [];
			var notify = null;
			const { NOTIFICATION, ...fieldErrors } = data.STATUS;
			if (data && data.STATUS) {
				if (NOTIFICATION) {
					notify = NOTIFICATION[0];
				}
				Object.keys(fieldErrors).forEach((name) => {
					errors.push({ name, errors: data.STATUS[name] });
				});
			}
			return Promise.reject({ errors, notify });
		});
	};
	getConsignmentTrackingData = (formdata) => {
		return Axios.post(
			`utilities/tracking/consignment`, formdata
		).then(({ data }) => {
			//this.setConsignmentTrackingValues(data.view)
			return data.view;
		})
		.catch(({ response: { data } }) => {
			var errors = [];
			var notify = null;
			const { NOTIFICATION, ...fieldErrors } = data.STATUS;
			if (data && data.STATUS) {
				if (NOTIFICATION) {
					notify = NOTIFICATION[0];
					this.trackingErrMsg = notify;
				}
				Object.keys(fieldErrors).forEach((name) => {
					errors.push({ name, errors: data.STATUS[name] });
				});
			}
			return Promise.reject({ errors, notify });
		});
	};

	getArchiveConsignmentTrackingJson = (payload) => {
		return Axios.post(
			`utilities/tracking/archive/consignment`, payload
		).then(({ data }) => {
			return data.view;
		})
		.catch(({ response: { data } }) => {
			var errors = [];
			var notify = null;
			const { NOTIFICATION, ...fieldErrors } = data.STATUS;
			if (data && data.STATUS) {
				if (NOTIFICATION) {
					notify = NOTIFICATION[0];
					this.trackingErrMsg = notify;
				}
				Object.keys(fieldErrors).forEach((name) => {
					errors.push({ name, errors: data.STATUS[name] });
				});
			}
			return Promise.reject({ errors, notify });
		});
	};

	// call api to bag Tracking records
	getbagTrackingData = (formdata) => {
		return Axios.post(
			`utilities/tracking/bag`, formdata
		).then(({ data }) => {
			this.setBagTrackingValues(data.view)
			return data.view;
		})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to packet Tracking records
	getPacketTrackingData = (formdata) => {
		return Axios.post(
			`utilities/tracking/packet`, formdata
		).then(({ data }) => {
			this.setPacketTrackingValues(data.view)
			return data.view;
		})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});

	};

	sendEmailTrack = (data) => {
        return Axios.post(`/utilities/tracking/send-pod-email`, data)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };
}

decorate(ManageTrackingStore, {
	setConsignmentTrackingValues: action,
	consignmentTrackingValues: observable,
	setBagTrackingValues: action,
	bagTrackingValues: observable,
	setPacketTrackingValues: action,
	packetTrackingValues: observable,
	setTrackingErrMsg: action,
	trackingErrMsg: observable
});
